* {
  box-sizing: border-box;
}

@keyframes fade-in {
	from {opacity: 0%;}
	to {opacity: 100%;}
}

.App {
  text-align: center;
  font-size: 18px;
}

body {
  background-color: var(--color-bg);
  background-image: url('../../background.jpg');
  background-size: cover;
}

.Loading, footer {
  color: var(--text-white);
}