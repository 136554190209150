.Header {
	font-family: 'Distant-Galaxy', 'Teko', sans-serif;
	background-color: var(--color-bg);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: yellow;
	letter-spacing: 2px;
  }