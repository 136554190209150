:root {
  --text-white: rgb(226, 226, 226);
  --text-dim: rgb(172, 172, 172);
  --color-bg: #1e2127;

  --fade-standard: 0.4s;
}

@font-face {
  font-family: 'Distant-Galaxy';
  src: url('sf_distant_galaxy_outline.ttf')
}

body {
  margin: 0;
  font-family: 'Share Tech', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
